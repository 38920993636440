import { useMediaQueries } from "@utils/hooks/useMediaQueries"
import { graphql, useStaticQuery } from "gatsby"
import { chunk } from "lodash"
import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { ClientIcon } from "./ClientIcon.component"
import * as Style from "./Clients.styles"

function Clients({ defaultIconColor, isInPortfolio = false }) {
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query ClientsLogos {
      allFile(
        filter: { sourceInstanceName: { eq: "clients" } }
        sort: { fields: childMarkdownRemark___frontmatter___order }
      ) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              title
              backgroundColor
              logo {
                childSvg {
                  content
                }
              }
              linkToCase
            }
          }
        }
      }
    }
  `)
  const { isMobile, isTablet } = useMediaQueries()

  const clients = nodes.map(node => node.childMarkdownRemark.frontmatter)

  const icons = clients.map((client, index) => (
    <ClientIcon
      defaultIconColor={defaultIconColor}
      key={index}
      client={client}
    />
  ))
  const responsiveGridConfig = isMobile
    ? Style.ClientsGrid.mobile
    : isTablet
    ? Style.ClientsGrid.tablet
    : Style.ClientsGrid.desktop(isInPortfolio)

  const chunks = chunk(
    icons,
    responsiveGridConfig.columns * responsiveGridConfig.rows
  )

  return (
    <Style.SliderWrapper>
      <Slider
        {...{
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          arrows: false,
        }}
      >
        {chunks.map((chunk, index) => (
          <Style.Grid isInPortfolio={isInPortfolio} key={index}>
            {chunk}
          </Style.Grid>
        ))}
      </Slider>
    </Style.SliderWrapper>
  )
}

export default Clients
