import React from "react"
import { ArrowLeft, ArrowRight } from "react-feather"
import { Box, Button, Flex } from "rebass"
import { SeeTheCaseButton } from "./SeeTheCaseButton"

function StepButton({ onClick, color, children, sx }) {
  return (
    <Button
      onClick={onClick}
      variant="step"
      sx={{
        transition: "border-color 0.2s ease",
        borderColor: color,
        svg: {
          transition: "stroke 0.2s ease",
          color,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  )
}
export function ButtonSteps({
  color,
  onClickPrevious,
  onClickNext,
  linkToCase,
  showLinkToCase = true,
  mt,
}) {
  return (
    <Flex flexWrap="wrap" mt={mt}>
      <Box mb={2}>
        <StepButton
          onClick={onClickPrevious}
          color={color}
          sx={{
            mr: 2,
          }}
        >
          <ArrowLeft />
        </StepButton>
        <StepButton
          onClick={onClickNext}
          color={color}
          sx={{
            mr: 4,
          }}
        >
          <ArrowRight />
        </StepButton>{" "}
      </Box>
      {showLinkToCase && (
        <SeeTheCaseButton
          sx={{
            mb: 2,
          }}
          linkToCase={linkToCase}
          color={color}
        />
      )}
    </Flex>
  )
}
