import React, { Fragment } from "react"
import routes from "@utils/routes"
import * as Style from "./IconGrid.styles"

function IconGrid({ icons }) {
  return (
    <Style.Wrapper>
      {icons.map((icon, index) => (
        <Style.Link to={routes.imprensa} key={index}>
          <Fragment>{icon}</Fragment>
        </Style.Link>
      ))}
    </Style.Wrapper>
  )
}

export default IconGrid
