import React from "react"
import * as Style from "./Testimonies.styles"

export function Deponent({ slide, translatedContent }) {
  return (
    <Style.Deponent>
      <Style.Deponent.CompanyLogo>
        <img alt={`${slide.company} logo`} src={slide.logo?.publicURL} />
      </Style.Deponent.CompanyLogo>
      <div>
        <Style.Deponent.Name>
          {translatedContent.clientName}
        </Style.Deponent.Name>
        <Style.Deponent.Position>
          {translatedContent.clientPosition}
        </Style.Deponent.Position>
      </div>
    </Style.Deponent>
  )
}
