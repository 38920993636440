import Card from "@components/Card"
import { WidthLimitation } from "@components/Content"
import Translate from "@components/Translate"
import { Link as IntlLink } from "gatsby-plugin-intl"
import React from "react"
import { Box, Flex, Heading, Text } from "rebass"

export function Section({ title, children, isGray, ...props }) {
  return (
    <Flex
      backgroundColor={isGray && "grayLight"}
      overflow="hidden"
      width="100vw"
    >
      <WidthLimitation marginY="5" marginX="auto">
        <Flex
          as="section"
          flexWrap="wrap"
          justifyContent="space-between"
          padding="3"
          width="100%"
          {...props}
        >
          {title}
          {children}
        </Flex>
      </WidthLimitation>
    </Flex>
  )
}

export function SectionImage({ children }) {
  return (
    <Box
      as="section"
      display="grid"
      sx={{
        width: "100%",
        mt: ["0", -6],
        mb: ["0", 4],
        padding: "3",
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit, 268px)",
        maxWidth: 1200,
        paddingX: 5,
        gap: 4,
      }}
    >
      {children}
    </Box>
  )
}

export function Link({ ...props }) {
  return (
    <IntlLink
      style={{
        textDecoration: "none",
      }}
      {...props}
    />
  )
}

export function SectionTitle({ ...props }) {
  return (
    <Heading
      sx={{
        fontWeight: "body",
        letterSpacing: "0px",
        fontSize: 5,
        color: "blueDark",
        mb: 2,
        textDecoration: "none",
        strong: {
          fontWeight: "bold",
        },
        "::after": {
          content: `"."`,
          background: "linear-gradient(90deg, #E86D30 0%, #DB4653 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        },
      }}
      as="h4"
      {...props}
    />
  )
}

export function SectionSubtitle({ ...props }) {
  return (
    <Text
      {...{
        fontHeight: "19px",
        fontSize: "16px",
        fontWeight: "light",
        letterSpacing: "0px",
        color: "blueNavy",
        mb: 2,
        fontFamily: "heading",
        width: "100%",
      }}
      as="p"
      {...props}
    />
  )
}

export function HomeCardTitle({ as, id }) {
  return (
    <Flex
      sx={{
        alignItems: "center",
        height: "60px",
        justifyContent: "center",

        h4: {
          fontSize: 4,
          color: "blueDark",
          lineHeight: "30px",
          fontWeight: "bold",
          fontFamily: "heading",
        },
      }}
    >
      <Text as="h4">
        <Translate id={id} />
      </Text>
    </Flex>
  )
}

export const HomeCardSubtitle = props => (
  <Text sx={{ fontSize: 2, color: "blueDark", my: 4 }} {...props} />
)

export function HomeCard(props) {
  return (
    <Card
      textAlign="center"
      sx={{
        borderRadius: "5px",
        display: "block",
        paddingX: 3,
        paddingY: 4,
        svg: {
          display: "inline-block",
          height: 165,
        },
      }}
      {...props}
    />
  )
}

export function ListImageSvg({ ...props }) {
  return (
    <Flex
      sx={{
        margin: "0 auto",
        width: "auto",
        "&:not(:last-child)": {
          mb: [3, 3, 0],
        },
      }}
      {...props}
    />
  )
}
