import routes from "@utils/routes"
import React from "react"
import ClientBackgroundShape from "../../images/clientBackground.svg"
import * as Style from "./Clients.styles"

export function ClientIcon({ client, defaultIconColor }) {
  return (
    <Style.PortfolioLink to={client.linkToCase || routes.portfolio}>
      <Style.Logo clientColor={client.backgroundColor}>
        <Style.ClientBackgroundWrapper className="background">
          <ClientBackgroundShape />
        </Style.ClientBackgroundWrapper>
        <Style.LogoSVGWrapper
          className="logo"
          defaultIconColor={defaultIconColor}
          dangerouslySetInnerHTML={{ __html: client.logo?.childSvg?.content }}
        ></Style.LogoSVGWrapper>
      </Style.Logo>
    </Style.PortfolioLink>
  )
}
