import Clients from "@components/Clients"
import ImagesSVG from "@components/ImagesSVG"
import { icons } from "@constants/icons"
import Testimonies from "@components/Testimonies"
import Translate, { TranslateHTML } from "@components/Translate"
import { homeContent } from "@constants/home"
import IconGrid from "@components/IconGrid"
import React, { Fragment } from "react"
import routes from "@utils/routes"
import * as Style from "./Home.styles"

const Home = ({ portfolioList }) => {
  return (
    <Fragment>
      <Style.SectionImage>
        {homeContent.card.map((item, index) => (
          <Style.HomeCard key={index}>
            <Style.HomeCardTitle as={item.title.as} id={item.title.label} />
            <Style.HomeCardSubtitle>
              <Translate id={item.subtitle.label} />
            </Style.HomeCardSubtitle>
            <ImagesSVG svg={item.image} />
          </Style.HomeCard>
        ))}
      </Style.SectionImage>

      <Style.Section
        title={
          <Style.Link to="/portfolio">
            <Style.SectionTitle>
              <TranslateHTML id="home.whoWeDevelop.whoWeDevelop" />
            </Style.SectionTitle>
          </Style.Link>
        }
      >
        <Clients defaultIconColor="mediumGray" />
      </Style.Section>

      <Testimonies cases={portfolioList} />

      <Style.Section
        title={
          <Style.Link to={routes.imprensa}>
            <Style.SectionTitle>
              <TranslateHTML id="home.midia.midia" />
            </Style.SectionTitle>
          </Style.Link>
        }
      >
        <IconGrid icons={icons} />
      </Style.Section>
    </Fragment>
  )
}

export default Home
