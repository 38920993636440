import React from "react"
import { Box, Flex, Text } from "rebass"

export const Wrapper = props => (
  <Box
    sx={{
      width: "100vw",
      paddingX: 4,
      backgroundColor: "grayLight",
    }}
    {...props}
  />
)

export const SliderControl = props => (
  <Box
    {...props}
    sx={{
      display: "flex !important",
      maxWidth: "1200px",
      margin: "0 auto",
      position: "initial",
      flexDirection: "column",
      ul: {
        display: "flex",
        mt: 3,
      },
      li: {
        listStyle: "none",
        mr: 2,
      },
      paddingTop: 3,
      paddingBottom: 5,
    }}
  />
)

export const PagingIndicator = ({ color, onClick, ...props }) => (
  <Box
    as="li"
    sx={{ py: "6px", cursor: "pointer" }}
    onClick={onClick}
    {...props}
  >
    <Box
      as="div"
      className="indicator"
      sx={{
        width: 35,
        height: 4,
        borderRadius: 99,
        background: "#C8C8D0",
        transition: "background 0.2s ease",
        ".active &": {
          background: color,
        },
      }}
    />
  </Box>
)

export const PagingWrapper = props => (
  <Box
    sx={{
      display: "flex",
    }}
    {...props}
  />
)

export const Slide = ({sx, ...props}) => (
  <Box
    sx={{
      maxWidth: "1200px",
      minHeight: "400px",
      margin: "0 auto",
      paddingTop: 5,
      ...sx,
    }}
    {...props}
  />
)

export const Deponent = props => (
  <Flex
    sx={{
      mt: 4,
      alignItems: "center",
      color: "blueDark",
      flexWrap: "wrap",
    }}
    {...props}
  />
)
Deponent.CompanyLogo = props => (
  <Box
    sx={{
      mr: 4,
    }}
    {...props}
  />
)

Deponent.Name = props => (
  <Text
    sx={{
      fontSize: 3,
      fontWeight: "bold",
      fontFamily: "heading",
    }}
    {...props}
  />
)
Deponent.Position = props => (
  <Text
    sx={{
      fontSize: 2,
      fontWeight: "body",
      fontFamily: "heading",
    }}
    {...props}
  />
)

Slide.Left = props => (
  <Flex
    sx={{
      flexDirection: "column",
    }}
    {...props}
  />
)
Slide.Right = props => (
  <Box
    sx={{
      alignSelf: "center",
    }}
    {...props}
  />
)
Slide.Content = props => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: ["1fr", "1fr 1fr", "1fr 1fr"],
      gap: 3,
      height: "100%",
    }}
    {...props}
  />
)
Slide.Content.TextContent = ({ strongColor, ...props }) => (
  <Box
    as="q"
    sx={{
      fontSize: [2, 3, 4],
      fontWeight: "body",
      color: "blueDark",
      fontFamily: "heading",
      strong: {
        fontWeight: "bold",
        color: strongColor,
      },
    }}
    {...props}
  />
)
