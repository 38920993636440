import { range } from "lodash"
import React, { useState } from "react"
import * as ButtonSteps from "./ButtonSteps"
import { Slide } from "./Slide"
import * as Style from "./Testimonies.styles"
import classNames from "classnames"

function Testimonies({ cases }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const moveToNext = () => {
    setActiveIndex(old => (old === cases.length - 1 ? 0 : old + 1))
  }
  const moveToPrevious = () => {
    setActiveIndex(old => (old === 0 ? cases.length - 1 : old - 1))
  }
  const activeSlide = cases[activeIndex]
  const activeSlideElement = cases.map((activeSlide, key) =>  <Slide
      key={key}
      slide={activeSlide}
      sx={{
        display: activeIndex === key ? "block" : "none"
      }}
      translatedContent={activeSlide.languages.find(
        language => language.language === process.env.GATSBY_LANGUAGE
      )}
    />)

  return (
    <Style.Wrapper>
      {activeSlideElement}
      <Style.SliderControl>
        <ButtonSteps.ButtonSteps
          color={activeSlide?.primaryColor}
          linkToCase={activeSlide?.linkToCase}
          onClickNext={() => moveToNext()}
          onClickPrevious={() => moveToPrevious()}
        />
        <Style.PagingWrapper>
          {range(cases.length).map(index => (
            <Style.PagingIndicator
              className={classNames({
                active: activeIndex === index,
              })}
              onClick={() => setActiveIndex(index)}
              color={activeSlide.primaryColor}
            />
          ))}
        </Style.PagingWrapper>
      </Style.SliderControl>
    </Style.Wrapper>
  )
}

export default Testimonies
