import CanalTechIcon from "@images/home/canalTech.svg"
import ComputerWorldIcon from "@images/home/computerWorld.svg"
import Inova360Icon from "@images/home/inova360.svg"
import ItmIcon from "@images/home/itm.svg"
import RecordNewsIcon from "@images/home/recordNews.svg"
import TvAlespIcon from "@images/home/tvAlesp.svg"
import React from "react"

export const icons = [
  <RecordNewsIcon />,
  <CanalTechIcon />,
  <Inova360Icon />,
  <TvAlespIcon />,
  <ComputerWorldIcon />,
  <ItmIcon />,
]
