import BaseLayout from "@components/BaseLayout"
import Hero from "@components/Hero"
import Home from "@components/Home"
import SEO from "@components/Seo"
import { graphql } from "gatsby"
import React from "react"

const IndexPage = ({ data }) => {
  const {
    metatag,
    portfolio: { edges: portfolios },
  } = data
  const portfoliosList = portfolios.map(portfolio => portfolio.node.frontmatter)
  const metatags = metatag.frontmatter.languages.find(
    lang => lang.language === process.env.GATSBY_LANGUAGE
  )
  return (
    <BaseLayout
      hero={
        <Hero
          title="header.hero.title"
          subtitle="header.hero.subtitle"
          hasContractLink
        />
      }
    >
      <SEO {...metatags} />
      <Home portfolioList={portfoliosList} />
    </BaseLayout>
  )
}

export const query = graphql`
  query MidiaListHome {
    metatag: markdownRemark(
      fileAbsolutePath: { glob: "**/content/metatags/home.md" }
    ) {
      frontmatter {
        languages {
          language
          keywords
          title
          description
        }
      }
    }
    portfolio: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/testimonials/*.md" } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            company
            languages {
              language
              description
              clientName
              clientPosition
            }
            primaryColor
            backgroundColor
            linkToCase
            logo {
              publicURL
            }
            image {
              childImageSharp {
                fluid(maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
