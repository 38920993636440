import Img from "gatsby-image"
import React from "react"
import { Box } from "rebass"

export function SlideImage({ image }) {
  return (
    <Box
      sx={{
        height: "100%",
        mx: 'auto',
        maxWidth: 400
      }}
    >
      <Img loading="eager" fluid={image?.childImageSharp?.fluid} />
    </Box>
  )
}
