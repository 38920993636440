import Translate, {
  TranslateHTML,
} from "@components/Translate/Translate.component"
import routes from "@utils/routes"
import { Link } from "gatsby-plugin-intl"
import React from "react"
import Fade from "react-reveal/Fade"
import * as Style from "./Hero.styles"
import { IdsDosBotoesDeConversaoDoLeadsCenter } from "@constants/forms"

function Hero({
  title,
  children,
  subtitle,
  rightSide,
  hasContractLink,
  isClear,
}) {
  return (
    <Style.Wrapper isClear={isClear}>
      <Fade left>
        <Style.LeftSide>
          <Style.HeroHeading isClear={isClear}>
            {title ? <TranslateHTML id={title} /> : children}
          </Style.HeroHeading>
          {subtitle && (
            <Style.HeroSubtitle isClear={isClear}>
              <TranslateHTML id={subtitle} />
            </Style.HeroSubtitle>
          )}
          {hasContractLink && (
            <Style.ButtonsWrapper>
              {process.env.GATSBY_LANGUAGE !== "en" && (
                <Style.HireSquadsButton
                  as={Link}
                  // href="https://perfil.x-apps.com.br/"
                  href={routes.contrateSquads}
                >
                  <Translate id="header.buttons.hireSquad2" />
                </Style.HireSquadsButton>
              )}
              <Style.BudgetButton
                as={Link}
                to={`${routes.contato}?form_id=${IdsDosBotoesDeConversaoDoLeadsCenter.hero}`}
              >
                <Translate id="header.buttons.receiveEstimation" />
              </Style.BudgetButton>
            </Style.ButtonsWrapper>
          )}
        </Style.LeftSide>
      </Fade>
      {rightSide && <Style.RightSide>{rightSide}</Style.RightSide>}
    </Style.Wrapper>
  )
}

export default Hero
