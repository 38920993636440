import { TranslateHTML } from "@components/Translate"
import React from "react"
import { SectionTitle } from "../Home/Home.styles"
import { Deponent } from "./Deponent"
import { SlideImage } from "./SlideImage"
import * as Style from "./Testimonies.styles"
import Fade from "react-reveal/Fade"

export function Slide({ slide, translatedContent, sx }) {
  if (!translatedContent) return null

  return (
    <Style.Slide sx={sx}>
      <Style.Slide.Content>
        <Style.Slide.Left>
          <Fade bottom>
            <SectionTitle>
              <TranslateHTML id="home.testimony.title" />
            </SectionTitle>
          </Fade>

          <Fade bottom>
            <Style.Slide.Content.TextContent
              dangerouslySetInnerHTML={{
                __html: `“${translatedContent.description}”`,
              }}
              strongColor={slide.primaryColor}
            />
          </Fade>

          <Fade bottom>
            <Deponent translatedContent={translatedContent} slide={slide} />
          </Fade>
        </Style.Slide.Left>
        <Style.Slide.Right>
          <Fade right>
            <SlideImage image={slide.image} />
          </Fade>
        </Style.Slide.Right>
      </Style.Slide.Content>
    </Style.Slide>
  )
}
