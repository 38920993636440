import Translate from "@components/Translate"
import routes from "@utils/routes"
import { Link } from "gatsby-plugin-intl"
import React from "react"
import { ArrowRight } from "react-feather"
import { Button } from "rebass"

export function SeeTheCaseButton({ linkToCase, sx, color, emptyIsHidden }) {
  if (emptyIsHidden && !linkToCase) return null
  return (
    <Button
      variant="step"
      as={Link}
      to={linkToCase ? linkToCase : routes.portfolio}
      sx={{
        borderColor: color,
        transition: "border-color 0.2s ease, color 0.2s ease",
        color: color,
        paddingX: 4,
        svg: {
          display: "inline-block",
          ml: 1,
          transition: "stroke 0.2s ease",
        },
        ...sx,
      }}
    >
      <Translate id="seeTheCase" /> <ArrowRight size={16} color={color} />
    </Button>
  )
}
